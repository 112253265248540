.history {
  width: 66%;
  max-height: 20rem;
  position: absolute;
  top: 2.75rem;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 20;

  .box {
    width: 98%;
    background-color: rgba($color: #ffffff, $alpha: 1);
    padding: 0.5rem 1%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .box:hover {
    width: 98%;
    background-color: rgb(171, 184, 188);
    padding: 0.5rem 1%;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.history::-webkit-scrollbar {
  display: none;
}
